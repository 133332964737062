@use '../../scss/themes/themes.mixin.scss' as *;
   
.power {
    cursor: pointer;
    &__icon {
      @include themify() {
        fill: themed('topbarDisconnectTextColor');
      }
    }
}
