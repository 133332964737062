@use '../../scss/themes/themes.mixin.scss' as *;

.money {
    cursor: pointer;
    
    &__icon {
    @include themify() {
        fill: themed('stroke');
      }
    }
}
   
