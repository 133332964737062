.legal-notice__legalNotice {
    margin-top: 40px;
    .legal-notice__legalNoticeBank a {
        text-decoration: underline!important;
    }
    .legal-notice__legalNoticeBank p {
        padding-left: 40px;
    }
}

.legal-notice__legalNotice li {
    list-style-type: none;
}

#legal-notice__back-btn {
    margin-left: 40px;
    margin-top: 30px;
}