@use '../../scss/themes/themes.mixin.scss' as *;

.email__delivered {
    cursor: pointer;
    
    &__icon {
    @include themify() {
        fill: themed('stroke');
      }
    }
}

.email__delivered__active {
    cursor: pointer;
    &__icon {
        @include themify() {
            fill: themed('fill');
        }
    }
}
   
