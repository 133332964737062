@use '../../scss/variables' as *;
@use '../../scss/themes/themes.mixin.scss' as *;

button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid;

  &:hover {
    transition: background-color 0.3s ease;
  }
  &:disabled {
    background-color: $grey-button!important;
    border-color: $grey-button!important;
    color: $white!important;
    cursor: default;
    pointer-events: none;
  }

  &.button__style {
    &-small {
      padding: .25rem 1rem !important;
      margin: 0 !important;
      font-size: small;
      white-space: nowrap;
      @include themify() {
        background-color: themed('buttonSecondaryBackgroundColor');
        border-color: themed('buttonSecondaryBorderColor');
        color: themed('buttonSecondaryTextColor');
      }
      &:hover {
        @include themify() {
          background-color: themed('buttonSecondaryHoverBackgroundColor');
          border-color: themed('buttonSecondaryHoverBorderColor');
          color: themed('buttonSecondaryHoverTextColor');
        }
      }
    }
    &-basic {
      padding: 10px 20px;
      cursor: pointer;
      @include themify() {
        border-radius: themed('buttonRadius');
      }
    }
  
    &-primary, &-test {
      @include themify() {
        background-color: themed('buttonPrimaryBackgroundColor');
        border-color: themed('buttonPrimaryBorderColor');
        color: themed('buttonPrimaryTextColor');
      }
      &:hover {
        @include themify() {
          background-color: themed('buttonPrimaryHoverBackgroundColor');
          border-color: themed('buttonPrimaryHoverBorderColor');
          color: themed('buttonPrimaryHoverTextColor');
        }
      }
    }
  
    &-blue, &-secondary {
      @include themify() {
        background-color: themed('buttonSecondaryBackgroundColor');
        border-color: themed('buttonSecondaryBorderColor');
        color: themed('buttonSecondaryTextColor');
      }
      &:hover {
        @include themify() {
          background-color: themed('buttonSecondaryHoverBackgroundColor');
          border-color: themed('buttonSecondaryHoverBorderColor');
          color: themed('buttonSecondaryHoverTextColor');
        }
      }
    }
  
    &-white, &-tertiary {
      @include themify() {
        background-color: themed('buttonTertiaryBackgroundColor');
        border-color: themed('buttonTertiaryBorderColor');
        color: themed('buttonTertiaryTextColor');
      }
      &:hover {
        @include themify() {
          background-color: themed('buttonTertiaryHoverBackgroundColor');
          border-color: themed('buttonTertiaryHoverBorderColor');
          color: themed('buttonTertiaryHoverTextColor');
        }
      }
    }
  
    &-red, &-danger {
      @include themify() {
        background-color: themed('buttonDangerBackgroundColor');
        border-color: themed('buttonDangerBorderColor');
        color: themed('buttonDangerTextColor');
      }
      &:hover {
        @include themify() {
          background-color: themed('buttonDangerHoverBackgroundColor');
          border-color: themed('buttonDangerHoverBorderColor');
          color: themed('buttonDangerHoverTextColor');
        }
      }
    }
  
    &-link {
      background-color: transparent;
      text-decoration: underline;
      border: 0;
      color: $white;
      padding: 6px;

      &:hover {
        color: $white;
      }
      &:disabled {
        background-color: $grey-button !important;
        border: 1px $light-blue solid !important;
        color: $white !important;
        cursor: default !important;
        pointer-events: none !important;
        &:hover {
          background-color: $grey-button !important;
          color: $white !important;
        }
      }
    }
  }
}

.width-100 {
  width: 100%;
}