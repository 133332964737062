@use '../../scss/themes/themes.mixin.scss' as *;
   
.user {
    cursor: pointer;

    &__icon {
    @include themify() {
        fill: themed('topbarMainTextColor');
      }
    }
}

   
