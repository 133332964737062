@use '../themes/themes.mixin.scss' as *;
@use '../variables' as *;

@mixin tooltip {
    @include themify() {
        background-color: themed('primaryColor') !important;
    }
    color: $white;
    font-size: 13px;
    text-align: center;
    &.place-top, &.place-right {
        &::after {
            @include themify() {
                border-top-color: themed('primaryColor') !important;
            }
            border-top-style: solid;
            border-top-width: 6px;
        }
    }
}