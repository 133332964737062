@use '../../scss/themes/themes.mixin.scss' as *;

.navbar {
  &__account {
    display: flex;
    align-items: center;
    gap: 40px;
    height: 100%;
  }

  &__infos {
    text-align: right;
    line-height: 1.2;
    font-size: 1.2rem;

    .type {
      font-size: 1.8rem;
      line-height: 2.5rem;
      font-weight: 600;
    }
  }

  &__links {
    display: flex;
    gap: 40px;
  }

  &__disconnection {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 30px;
    height: 100%;
    cursor: pointer;
    @include themify() {
      color: themed('topbarDisconnectTextColor');
      background-color: themed('topbarDisconnectBackgroudColor');
    }
  }
}
