@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;

.question__mark {
    cursor: pointer;
    
    &__icon {
    @include themify() {
        fill: themed('fill');
      }
    }
}

.question__mark__login {
  cursor: pointer;
  
  &__icon {
      fill: $catalizr-primary-color;
  }
}
