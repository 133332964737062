@use '../../scss/variables' as *;

.footer__main {
  font-size: 1rem;
  color: $white;
  position: relative;
  bottom: 0;
  width: 100%;

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}
