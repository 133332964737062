@use "sass:math";

$z-index-ranges: (
  'background': (-100, -1),
  'base': (0, 0),
  'ground-floor-min': (1, 5),
  'ground-floor-max': (6, 9),
  'low': (10, 99),
  'mid': (100, 999),
  'high': (1000, 9999),
  'stratosphere': (10000, 999999)
);

@function z-range($range, $position: 'start') {
  $range-values: map-get($z-index-ranges, $range);
  @if not $range-values {
    @error "Plage de z-index inconnue : #{$range}";
  }
  $start: nth($range-values, 1);
  $end: nth($range-values, 2);

  @if $position == 'start' {
    @return $start;
  } @else if $position == 'end' {
    @return $end;
  } @else if type-of($position) == 'number' {
    @if $position < 0 or $position > 100 {
        @error "La position doit être entre 0 et 100, reçu : #{$position}";
      }
    @return math.div(($end - $start) * $position, 100);
  } @else {
    @error "Position invalide pour z-range. Utilisez 'start', 'end', ou un nombre entre 0 et 100.";
  }
}

@mixin z-index($range, $position: 'start') {
  z-index: z-range($range, $position) !important;
}
