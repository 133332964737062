@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;

.registration__main {
  @include themify() {
    background-color: themed('primaryColor');
  }
  text-align: center;
  color: $black;
  height: 100vh;
  position: relative;
  min-height: 800px;
  overflow-y: auto;

  h1 {
    color: $white;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 50px;
  }
}

.bank-logo {
  max-width: 350px;
  max-height: 100px;
  margin-bottom: 18px;
}

.registration__logo {
  margin: 4em 2em 2em 2em;
}

.registration__form {
  background-color: $white;
  padding: 2em;
  margin: 0 auto 2em;
  width: 400px;
  border-radius: 4px;
  button {
    border-radius: 4px;
    float: right;
  }
  .custom-checkbox {
    font-size: 1.2rem;
    color: $grey-text;
    float: left;
    margin-top: 12px;
    label {
      margin-left: 3px;
    }
  }
  label {
    text-align: left;
    display: block;
  }
  input {
    padding: 1rem;
  }
  button {
    border-radius: 4px;
    float: right;
    // text-align: center;
    // width: 40%;
  }
  .register__form-description {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 0.3px;
    text-align: left;
    color: #939393;
  }
}

.registration__form-description {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 0.3px;
  text-align: left;
  color: #939393;
}

.registration__input-field {
  margin: 2.5rem auto;
}

.registration__feedback {
  text-align: center;
  padding: 10px;
  margin: 10px 0;
}

.registration__link-forgot-pwd {
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  color: $sky-blue;
  text-decoration: underline;
  cursor: pointer;
}

.registration__ling-forgot-pwd:hover {
  color: $sky-blue;
  cursor: pointer;
}
