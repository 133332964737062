// Catalizr default colors palette
$default: (
  // COLORS
  grey-button: #d8d8d8,
  light-blue: #eceef7,
  primary-color: #2a3ea3,
  yellow-button: #efb935,
  // WIDTH
  button-radius: 0px
);

// Crédit Agricole colors palette
$ca: (
  // COLORS
  primary-color: #007461,
  secondary-01: #308276,
  secondary-02: #a0d7a2,
  secondary-03: #004652,
  grey-200: #f7f8fa,
  grey-300: #ecedf0,
  grey-800: #071621,
  accent: #058193,
  // WIDTH
  button-radius: 10px
);

$lcl: (
  // COLORS
  primary-color: #1f368b,
  secondary-01: #3b51d5,
  secondary-02: #ffd300,
  secondary-03: #193394,
  secondary-04: #727efd,
  secondary-05: #ffa702,
  danger: #ff6a00,
  grey-200: #f7f8fa,
  grey-300: #ecedf0,
  grey-800: #071621,
  // WIDTH
  button-radius: 10px
);

/*
 *
 * VARIABLES USED REGARDLESS THE THEME
 *
 */

// ALERT
$alert-success: #113019;
$alert-success-light: #9bc3a6;
$alert-info: #2a3ea3;
$alert-info-light: #eceef7;
$alert-warning: #f4832b;
$alert-warning-light: #ffdfc6;
$alert-error: #a32a2a;
$alert-error-light: #f3beba;

// ICON
$on-hold: #efb935;
$confirmed: #20c997;

// COLORS
$black: #000000;
$blue-info-light: #4798ca; // Banner
$blue-info: #0a7abf; // Banner et _forms.scss
$catalizr-primary-color: #2a3ea3;
$charcoal-black: #201f1f; // input percent et mixim fields
$charcoal: #495057; // 2 utilisations : account et invest details
$dark-grey: #858585; // dropdown select, input percent et mixin fields
$grey-blue-light: #d4d9ed; // une seule utilisation : account
$grey-button: #d8d8d8;
$grey-disabled: #808080;
$grey-extra-light: #ced4da;
$grey-landing: #a8a9ab; // une seule utilisation : landing
$grey-light: #969696; // 2 utilisations : DocManageInvest et account
$grey-text: #5f5f5f;
$light-blue: #eceef7;
$light-green-error: #9bc3a6; // une seule utilisation : .alert-success
$light-green-timeline: #113019; // une seule utilisation : .alert-success
$light-red-bright: #ff6256;
$light-red-error: #f3beba; // modal isin et .alert-danger
$light-red: #eb6862; // modal isin et banner
$light-yellow: #ffedb5;
$periwinkle-gray: #bfc5e3; // une seule utilisation : commentaires dans l'invest details
$red: #a32a2a; // pas fortement utilisée
$sky-blue: #1aa5df; // pas fortement utilisée
$warning-light: #ff9747; // Banner
$warning: #f4832b; // Banner
$white: #ffffff;
// WIDTH
$page-width: 1024px;
$input-radius: 10px;
