@use '../../scss/themes/themes.mixin.scss' as *;

.catalizr__logo__inline {
    cursor: pointer;
    
    &__icon {
    @include themify() {
        fill: themed('catalizrLogoColor');
      }
    }
}
   
