@use '../../scss/themes/themes.mixin.scss' as *;
   
.legal__notice {
    cursor: pointer;
    &__icon {
      @include themify() {
          fill: themed('topbarMainTextColor');
        }
      }
}


   
