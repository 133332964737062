@use '../../scss/variables' as *;

.wrapper {
  padding-block: 20px;
  color: $black;
  font-weight: 400;
}

section {
  margin-block: 20px;
}
p {
  margin-block: 20px;
}
.title {
  font-size: 16px;
  font-weight: 700;

  &--center {
    text-align: center;
  }
  &--italic {
    font-style: italic;
  }
}
