@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;

.catalizr-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 1.25rem 0;
  padding: 1.25rem;
  border-radius: 1rem;
  &__success {
    background-color: $alert-success-light;
    color: $alert-success;
  }
  &__info {
    background-color: $alert-info-light;
    color: $alert-info;
  }
  &__warning {
    background-color: $alert-warning-light;
    color: $alert-warning;
  }
  &__error {
    background-color: $alert-error-light;
    color: $alert-error;
  }
  &__icon, &__action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: .25rem 0;
    &.center {
      text-align: center;
    }
  }
}