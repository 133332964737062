@use './themes.scss' as themes-var;

// this mixin allows you to define conditional styles based on the active theme defined by the data-theme attribute. 
// It extracts the properties of the current theme from the $themes variable and stores them temporarily in $theme-map. 
// You can use this mixin to apply specific styles to each theme based on the value of data-theme on the parent element.

// set the default setting to $themes
@mixin themify() {
  // iterates through each theme defined in $themes. $theme : contains the theme name - $map : contains the values ​​associated with that theme
  @each $theme, $map in themes-var.$themes {
    // conditional selector : styles defined inside this selector will only be applied if the parent element has the data-theme attribute equal to $theme.
    .theme--#{$theme} & {
      // create an empty $theme-map local variable. This variable will be used to temporarily store the properties of the current theme.
      $theme-map: () !global;
      // iterates through all the properties and values ​​of the current theme ($map).
      @each $key, $submap in $map {
        // For each property ($key) and submap ($submap), the mixin extracts the corresponding value from the current theme using map-get and stores it in the $value variable.
        $value: map-get(map-get(themes-var.$themes, $theme), '#{$key}');
        // updates the $theme-map variable using map-merge. This adds the key-value pairs extracted from the current theme to $theme-map.
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      // use @content to include all content specified when you call the mixin. This means that the content inside the call to the mixin will be inserted there.
      @content;
      // Finally, the mixin resets $theme-map to null !global;.
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}