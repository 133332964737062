@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;

.login__container {
  height: 100vh;
  width: 100%;
  @include themify() {
    background-color: themed('primaryColor');
  }
  overflow-y: auto;
  text-align: center;
  color: $black;

  &__catalizr-logo {
    text-align: center;
    margin: 9px 5px 5px 5px;
    h1 {
      color: $white;
      font-size: 5rem;
      font-weight: 600;
      margin-bottom: 50px;
      }
    }
  &__bank-logo {
    max-width: 350px;
    max-height: 100px;
    margin-bottom: 18px;
  }

  &__form {
    width: 400px;
    background-color: $white;
    padding: 2em;
    margin: 0 auto 2em;
    border-radius: 4px;

    &__group {
      margin: 1.5rem;
      display: flex;
      flex-direction: column;

      &__item {
        margin: 10px 5px 10px 5px;
        .input{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          [data-baseweb="input"] {
            margin-top: 4px;
          }   
        }
        &__link {
          margin: 8px 0px 0px 0px;
          display: flex;
        }  
        &__checkbox {
          margin: 8px 0px 0px 0px;
          display: flex;
        }  
      }
      &__actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0px 0px 0px;
        &__hidden {
          visibility: hidden;
        }
      }
    }
  }  
  
  &__form__wrong-url {
    background-color: $white;
    width: 600px;
    margin: 0 auto 2em;
    padding: 2em;
  }
} 

.login__feedback {
  text-align: center;
  padding: 10px;
  margin: 10px 0;
}

.login__redirect-feedback {
  text-align: center;
  padding: 10px;
}

#login__link-forgot-pwd, #login__link-forgot-pwd__error-message {
  cursor: pointer;
}
#login__link-forgot-pwd {
  color: rgb(42, 62, 163);
  font-weight: 14px;
  margin: 0 0 0 9px;
  vertical-align: middle;
  display: inline-block;
}
#login__link-forgot-pwd__error-message {
  color: $sky-blue;
  text-decoration: underline;
}