@use '../../scss/themes/themes.mixin.scss' as *;

.email__problem {
    cursor: pointer;
    
    &__icon {
    @include themify() {
        fill: themed('fill');
      }
    }
}
   
