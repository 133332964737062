@use '../../scss/mixins/z-index.mixin.scss' as *;

.counter {
  font-weight: 400;
  background: pink;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
  @include z-index('stratosphere', 'end'); 
  border: 1px solid rgb(79, 98, 148);
  box-shadow: 0 0 4px rgb(79, 98, 148);
}
