@use '../../scss/variables' as *;

.banner {
  width: 100%;
  text-align: left;
  display: flex;

  &__icon {
    width: 90px;
    color: $white;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__content {
    width: 100%;
    padding: 2rem;
  }

  &__title {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }

  &--DANGER {
    border-right: 4px solid $light-red-bright;
    background-color: lighten($light-red-bright, 30%);
    color: $light-red;

    .banner__icon {
        background-color: $light-red-bright;
    }

    .banner__title {
      color: $light-red-bright;
    }
  }

  &--WARNING {
    border-right: 4px solid $warning;
    background-color: lighten($warning, 37%);
    color: $warning-light;

    .banner__icon {
        background-color: $warning;
    }

    .banner__title {
      color: $warning;
    }
  }

  &--INFO {
    border-right: 4px solid $blue-info;
    background-color: lighten($blue-info, 58%);
    color: $blue-info-light;

    .banner__icon {
        background-color: $blue-info;
    }

    .banner__title {
      color: $blue-info;
    }
  }
}
