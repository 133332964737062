@use '../../scss/mixins/fields.mixins.scss' as mixin_field;
@use '../../scss/mixins/tooltip.mixins.scss' as mixin_tooltip;

.input-field,
input.input-field {
  .form & {
    @include mixin_field.form-field(43px);
  }
  @include mixin_field.form-field(43px);

  &__password-wrapper {
    position: relative;
  }
  &__password-visibility {
    top: 31%;
    right: 4%;
    position: absolute;
  }

  &--with-icon {
    padding-right: 45px !important;
  }
  &__tooltip {
    @include mixin_tooltip.tooltip;
    max-width: 40%;
  }
}

// Edit Safari key icon to avoid overlap with eye icon
input::-webkit-credentials-auto-fill-button {
  margin-right: 0;
}

// Hide eye icon from IE / Edge browsers on password input
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.input-field__label, .input-field__label--disabled {
  display: flex;
  .error {
    color: red;
  }
}
.input__label {
  display: flex;
  .error {
    color: red;
  }
}
.error {
  color: red;
}

.checkbox__error {
  margin-left: 3rem;
  margin-bottom: 1rem;
  margin-top: 1rem !important;
}
