.portal__content {
  .portal__button:nth-child(2) {
    margin-left: 15px;
  }

  .login__form {
    height: 240px;
    width: 400px;
    div[data-baseweb="select"] {
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .picky__dropdown {
      overflow-y: auto !important;
      width: 314px;
      top: 49px;
    }
  }

  .portal__error-message {
    margin-top: 30px;
    line-height: 3rem;
  }

  .buttons {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    .portal__button {
      flex: auto;
      width: 155px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .connect-button {
      margin-right: 0px;
    }
  }
}
