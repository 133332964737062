@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/mixins/z-index.mixin.scss' as mixin;

.navbar {
  height: 65px;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  @include themify() {
    background-color: themed('topbarMainBackgroundColor');
    color: themed('topbarMainTextColor');
  }

  &__brand {
    cursor: pointer;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
