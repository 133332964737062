@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;

.login__main,
.registration__main {
  @include themify() {
    background-color: themed('primaryColor');
  }
  text-align: center;
  color: $black;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  h1 {
    color: $white;
    font-size: 50px;
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 50px;
  }
  &__footer {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
  }
  &__error {
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
  }

  .form-text {
    &.error {
      color: $red !important;
      font-size: 1.3rem;
    }
  }
}

.login__logo {
  margin: 4em 2em 2em 2em;
}

.login__form {
  background-color: $white;
  padding: 2em;
  margin: 0 auto 2em;
  width: 400px;
  border-radius: 4px;
  label {
    text-align: left;
    display: block;
  }
  input {
    padding: 1rem;
  }
  button {
    border-radius: 4px;
    float: right;
    // text-align: center;
    // width: 40%;
  }
  .forgot-pwd {
    font-size: 12px;
    font-size: 1.2rem;
    color: $sky-blue;
    a {
      vertical-align: middle;
      display: inline-block;
      margin: 0;
      color: $sky-blue;
      &:hover {
        color: $sky-blue;
      }
    }
  }
  .custom-checkbox {
    font-size: 12px;
    font-size: 1.2rem;
    color: $grey-text;
    float: left;
    margin-top: 12px;
    label {
      margin-left: 3px;
    }
  }
  .login__form-description {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 0.3px;
    text-align: left;
    color: #939393;
  }
}

.login__footer {
  font-size: 10px;
  font-size: 1rem;
  color: $white;
  position: absolute;
  bottom: 0;
  width: 100%;
  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}

.login__input-field {
  margin: 2.5rem auto;
  // width: 90%;
}

#login__forgot-password {
  text-decoration: underline;
}
#login__forgot-password:hover {
  cursor: pointer;
}
