@use '../../scss/themes/themes.mixin.scss' as *;
   
.help__circle {
    cursor: pointer;

    &__icon {
      @include themify() {
          fill: themed('topbarMainTextColor');
          stroke: themed('topbarMainBackgroundColor');
        }
      }
}


   
