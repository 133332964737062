@use '../../scss/themes/themes.mixin.scss' as *;

.catalizr__logo__square {
    margin: 45px 0px 20px 0px;
    cursor: pointer;
    
    &__icon {
    fill: white;
    }
}
   
