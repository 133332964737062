@use './scss/themes/themes.mixin.scss' as *;

html {
  font-size: 62.5%;
  height: 100vh;
}

body {
  font-family: 'Poppins';
  font-size: 14px;
  font-size: 1.4rem;
  height: 100vh;
}

* {
  outline: none;
}