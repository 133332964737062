@use '~bootstrap/scss/bootstrap';
@use './scss/variables' as *;
@use './scss/forms';
@use './scss/themes/themes.mixin.scss' as *;
@use './scss/mixins/z-index.mixin.scss' as *;

a {
  cursor: pointer;
}

.root__main {  
  position: relative;
  min-width: $page-width;
  margin: 0 auto;
  @include themify() {
    color: themed(textColor);
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: default;
  font-size: 1.6rem;
  margin-bottom: 0;
  select {
    cursor: pointer;
  }
}

.container span ~ span {
  position: relative;
  bottom: -2px;
}

.main__content {
  position: relative;
  width: $page-width;
  margin: auto;
}

.box-big-bd-left {
  background-color: $white;
  @include themify() {
    border: 1px solid themed('primaryColor');
  }
  border-left-width: 10px;
  padding: 15px;
  padding-bottom: 15px;
  margin-bottom: 28px;
}

.box-big-bd-left-timeline {
  @include themify() {
    background-color: $white;
    border: 1px solid themed('primaryColor');
    border-left-width: 10px;
    padding-left: 7px;
    padding-right: 10px;
    padding-bottom: 15px;
  }
}

.font-style-italic {
  font-style: italic;
}

.align-center {
  text-align: center;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.menu-left__button {
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
}

.error-message {
  color: red;
}

.error-input {
  color: red;
  margin-top: 10px;
}

.modal__error {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

.text--underlined {
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.row > .action-button__container, .search-and-actions__container {
  flex-shrink: initial;
  width: initial;
}

.alert-info {
  color: $blue-info;
  background-color: $light-blue;
  border-color: $blue-info;
}

.alert-danger {
  color: $red;
  background-color: $light-red-error;
  border-color: $light-red-error;
  padding: 8px;
  text-align: center;
}

.alert-success {
  color: $light-green-timeline;
  background-color: $light-green-error;
  border-color: $light-green-error;
}

// overrides z-index for wysiwyg component dropdown menu
.tox {
  @include z-index('stratosphere', 'end');  
}

@media (min-width: 768px) {
  .container {
    max-width: 95%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 95%;
  }
}