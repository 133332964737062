@use '../../scss/themes/themes.mixin.scss' as *;

.chat {
    cursor: pointer;
    &__icon {
    @include themify() {
        fill: themed('stroke');
      }
    }
}

.chat__active {
  cursor: pointer;
  &__icon {
  @include themify() {
      fill: themed('fill');
    }
  }
}
   
