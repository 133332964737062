@use '../scss/themes/themes.mixin.scss' as *;
@use '../scss/variables' as *;

.form {
  font-size: 1.4rem;
  h3 {
    padding-left: 1%;
    margin-top: 20px;
    color: $black;
    font-size: 20px;
    font-size: 2rem;
  }
  p {
    &.small {
      color: $grey-text;
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
  .row {
    display: block;
    margin: 0;
    padding: 0;

    &--flex {
      display: flex;
    }
    .col {
      margin: 0;
      padding: 1%;
      display: inline-block;
      vertical-align: top;

      &.w-4 {
        width: 25%;
      }

      &.w-3 {
        width: 33.33%;
      }
      &.w-2 {
        width: 50%;
      }
      &.w-1 {
        width: 100%;
      }
      &.w-12d5 {
        width: 12.5%;
      }
      &.w-37d5 {
        width: 37.5%;
      }
      &.w-75 {
        width: 75%;
      }
    }
    .form-input {
      margin-top: 0.5rem;
    }
  }
  input:not([type='radio']) {
    position: relative;
    border-radius: 0;
    height: auto !important;
  }
  .select {
    background-color: $white;
    padding: 1rem;
    border-radius: 0;
  }
  .warning {
    color: $warning;
    border-color: $warning;
    .input-field {
      border-color: $warning !important;
    }
    select {
      border-color: $warning;
    }
    .text-muted {
      color: $warning !important;
    }
  }

  .error {
    color: $red;
    border-color: $red;
    .input-field {
      border-color: $red !important;
    }
    select {
      border-color: $red;
    }
    .text-muted {
      color: $red !important;
    }
  }

  .warning + small {
    color: $blue-info !important;
  }

  .react-autosuggest__container {
    input {
      @extend .form-control;
      position: relative;
      background-color: $white;
      border-radius: 1px;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: auto !important;
      padding: 1rem;
      border: none;
    }
    input:focus {
      box-shadow: none;
    }
  }
}

.form-control {
  font-size: 14px;
  font-size: 1.4rem;
}

.input-group {
  display: inline-block;
  &-addon {
    background-color: $grey-button;
    padding: 10px 15px;
    height: 50px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: inline-block;
    vertical-align: top;
  }
  input {
    height: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-password {
    width: 92%;
    display: inline-block;
    vertical-align: top;
    input[type='text'] {
      margin: 0 !important;
    }
  }
}

.input-group.icon-right {
  .input-group-addon {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.form-group {
  &.error {
    color: $red;
    border-color: $red;
    input {
      color: $red;
      border-color: $red;
    }
    select {
      border-color: $red;
    }
    .text-muted {
      text-align: left;
      color: $red !important;
    }
  }

  .error + small {
    text-align: left;
    color: $red !important;
  }

  .warning + small {
    text-align: left;
    color: $blue-info !important;
  }
}

.form-control::-webkit-input-placeholder {
  color: $grey-extra-light;
}
.form-control:-moz-placeholder {
  color: $grey-extra-light;
}
.form-control::-moz-placeholder {
  color: $grey-extra-light;
}
.form-control:-ms-input-placeholder {
  color: $grey-extra-light;
}
.form-control:disabled {
  opacity: 0.6;
  background-color: white !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  -moz-appearance: textfield;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.flex {
  &--end {
    align-items: flex-end;
  }
}

.label {
  &--disabled {
    color: $grey-disabled !important;
  }
}
